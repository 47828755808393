<template>
  <div style="margin-top:75px;">
    <div class="w-100 headimg6">
      <!-- <img class="w-100" src="../assets/product/banner.jpg" alt=""> -->
      <div style="margin-top: 280px;font-size: 40px;"></div>
      <div style="margin-top: 20px;font-size: 28px;"></div>

      <div style="display: flex;margin-top: 400px;justify-content: space-around;width: 1024px; cursor: pointer;">
        <div>联系人</div>
        <div>联系电话</div>
        <div>您的需求</div>
        <div>项目周期</div>
        <div>联系客服</div>
      </div>
    </div>
    <div class="w-100">
      <div style="width: 100%;text-align: center;font-size: 20px;font-weight: 600;margin-top: 100px;">SCIENCE AND
        TECHNOLOGY SERVICE</div>
      <div
        style="width: 100%;text-align: center;font-size: 25px;font-weight: 600;margin-top: 10px;color: #000;letter-spacing: 4px;">
        联系我们</div>
    </div>

    <div class="ProductBox container " style="width: 55%;margin-bottom: 50px;">
      <el-form label-position="top" label-width="100px" :model="ruleForm" :rules="rules" ref="ruleForm">
        <div class="row">
          <el-form-item label="联系人" style="text-align: start;" prop="name" class="col-12 col-lg-6 col-md-12 col-sm-12">
            <el-input v-model="ruleForm.name" placeholder="请输您的姓名" style="background-color: #f5f6f7;"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" style="text-align: start;" prop="phone"
            class="col-12 col-lg-6 col-md-12 col-sm-12">
            <el-input v-model="ruleForm.phone" placeholder="请输入您的电话"></el-input>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="您的需求" style="text-align: start;" prop="remarks"
            class="col-12 col-lg-6 col-md-12 col-sm-12">
            <el-input v-model="ruleForm.remarks" placeholder="请输入您的需求"></el-input>
          </el-form-item>
          <el-form-item label="项目周期" style="text-align: start;;" prop="cycle"
            class="col-12 col-lg-6 col-md-12 col-sm-12">
            <el-input v-model="ruleForm.cycle" placeholder="请输入您的项目周期"></el-input>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="详细描述" style="text-align: start;width: 100%;" prop="description">
            <el-input v-model="ruleForm.description" placeholder="请输入您的描述" type="textarea" :rows="5"></el-input>
          </el-form-item>
        </div>

      </el-form>
      <el-button type="primary" style="width: 40%;margin-bottom: 50px;background-color: #145b92;"
        @click="submitForm('ruleForm')" :loading="submitloading">立即提交</el-button>
    </div>
  </div>
</template>

<script>
// 验证手机 
let checkPhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/
  if (!reg.test(value)) {
    callback(new Error('请输入11位手机号'))
  } else {
    callback()
  }
}
import { getList, joinAdd } from '../utils/ContactUs'
export default {

  data() {
    return {
      //提交按钮状态
      submitloading: false,
      contacList: [],
      ruleForm: {
        name: undefined,
        phone: undefined,
        remarks: undefined,
        // description:undefined,
        // cycle:undefined
      },
      rules: {
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
          { min: 0, max: 15, message: '长度在 0 到 15 个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入您的手机号', trigger: 'blur' },
          { type: 'number', validator: checkPhone, message: '请输入11位有效手机号号码', trigger: ['blur', 'change'] }
        ],
        remarks: [
          { required: true, message: '请输入您的需求', trigger: 'blur' },
        ],
      },
      loading: null
    };
  },
  mounted() {
    this.loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });

    this.zpList()

  },
  methods: {
    zpList() {
      getList({
        state: 0
      }).then((res) => {
        this.contacList = res.data
        this.loading.close();
      })
    },
    submitForm(ruleForm) {
      this.submitloading = true
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          // alert('submit!');
          joinAdd(this.ruleForm).then((res) => {
            // console.log(res);
            this.submitloading = false
            if (res.data.code == 200) {
              this.$message({
                message: '提交成功',
                type: 'success'
              });
              this.$refs[ruleForm].resetFields();
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style>
.headimg6 {
  width: 100%;
  background-image: url('../assets/uswm/u1.jpg');
  height: 750px;
  background-size: 100% 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.zpboxlist {
  /* width: 100%; */
  min-height: 150px;
  /* border: 1px solid red; */
  background-image: url('../assets/icon/zpgx.png');
  border: 1px solid rgb(88, 131, 255);
  /* background-size: 5% 35%; */
  background-repeat: no-repeat;
  border-radius: 20px;
  cursor: pointer;
}

.zptop {
  width: 100%;
  padding-left: 20px;
  padding-top: 20px;
  text-align: end;
}

.zpleft {
  /* display: flex; */
  font-size: 17px;
}

.zphead {
  color: #000;
  display: flex;
}

.zpright {
  color: #B1B1B1;
  font-size: 14px;
  display: flex;
  /* align-items: center; */
}
</style>