import request from './request.js'

// 重甲加入我们  招聘岗位查询
export function getList(params) {
  return request({
    url: '/recruitmentList',
    method: 'get',
    params
  })
}


// 重甲加入我们  在线留言
export function joinAdd(data) {
    return request({
      url: '/joinAdd',
      method: 'post',
      data
    })
  }
  
// // 聊天记录查询
// export function getchat(params) {
//   return request({
//     url: '/getchat',
//     method: 'get',
//     params
//   })
// }
