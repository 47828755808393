<template>
    <div style="margin-top:75px;">
        <div class="w-100 headimg">
            <!-- <img class="w-100" src="../assets/product/banner.jpg" alt=""> -->
            <div style="margin-top: 280px;font-size: 40px;">重甲电梯系列</div>
            <div style="margin-top: 20px;font-size: 28px;">ELEVATOR SERIES</div>

            <div style="display: flex;margin-top: 300px;justify-content: space-around;width: 1024px; cursor: pointer;">
                <div>S系列电梯</div>
                <div>B系列电梯</div>
                <div>Z系列电梯</div>
                <div>仓库电梯</div>
                <div>货梯电梯</div>
            </div>
        </div>
        <div class="ProductBox container" style="width: 50%;margin-bottom: 100px;">


            <div class="producthead prhead">
                <div>山东重甲重工</div>
                <!-- <div style="width: 30px;height: 1px;background-color: black;margin-top: 10px;"></div> -->
            </div>

            <div class="prtext">
                1853年在纽约水晶宫举行的世界博览会上，伊莱沙·格雷夫斯·奥的斯向世人展示了人类历史上第一部安全升降梯，彻底改写人类使用升降工具的历史。21世纪初，
                以芬兰、瑞士为首的技术派登顶电梯金字塔的塔尖。重甲创始人在经过多年的欧洲项目合作、生产研发经验后，将先进技术和原班人马引入中国

            </div>
            <div class="prtext">
                于2017年在山东济南正式创立山东重甲，致力成为全球电梯科技行业的领航者，携手全球合作伙伴让便捷、智能、安全的电梯通行体验遍布世界每一个角落，
                为人类的城市生活注入无限活力与可能。
            </div>

            <div class="w-100 ba1img">
                <img class="w-100 ba1img1" src="../assets/product/c1.jpg" alt="">
            </div>

            <div class="w-100">
                <div class="conList">
                    <div class="w-90 row conCoL">
                        <div class="col-lg-6 col-sm-12 CoLleft">
                            <div style="font-size: 18px;">S系列家用电梯</div>
                            <div style="font-size: 12px;font-weight: 400;margin-top: 10px;">
                                S系列家用电梯是重甲平台最顶级的家用电梯系列。这是一款获得无数高端客户认可。它有着像艺术背景墙奢华设计细节，可以用SmartLift手机App随意控制的艺术灯光情调照明
                            </div>
                            <div class="CoLbutt">
                                点击资讯 >
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12" style="padding: 0;">
                            <img src="../assets/product/c2.jpg" alt=""
                                style="" class="CoRightImg">
                        </div>
                    </div>
                </div>

                <div class="conList" style="margin-top: 20px;">
                    <div class="w-90 row conCoL1">
                        <div class="col-lg-6 col-sm-12" style="padding: 0;">
                            <img src="../assets/product/c3.jpg" alt=""
                                style="" class="CoRightImg1">
                        </div>

                        <div class="col-lg-6 col-sm-12 CoLleft">
                            <div style="font-size: 18px;">B系列家用电梯</div>
                            <div style="font-size: 12px;font-weight: 400;margin-top: 10px;">
                                作为重甲最节省空间的产品，b系列款家用电梯凭借其占地面积小、空间利用率高的优势，为空间有限的家庭住宅开启了无限可能。精致的尺寸和设计，使其可以同时满足舒适度空间和设计的要求。
                            </div>
                            <div class="CoLbutt">
                                点击资讯 >
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div class="conList" style="margin-top: 20px;">
                    <div class="w-90 row conCoL">
                        <div class="col-lg-6 col-sm-12 CoLleft">
                            <div style="font-size: 18px;">Z系列家用电梯</div>
                            <div style="font-size: 12px;font-weight: 400;margin-top: 10px;">
                                Z系列家用电梯可以让您和您的家人以及朋友方便地到达家中的各个角落。既实用又智能，其尺寸可满足不同的用梯需求可以个性化定制，如玻璃井道、材质选择等细节，对于老人小孩和身体不便的特殊人群，这款电梯都是不二之选。
                            </div>
                            <div class="CoLbutt">
                                点击资讯 >
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12" style="padding: 0;">
                            <img src="../assets/product/c4.jpg" alt=""
                                style="" class="CoRightImg">
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>

export default {
    name: 'ProductDisplay',
    components: {

    },

}
</script>

<style>
.jianjie {
    width: 100%;
    /* height: 600px; */
    min-height: 600px;
    background-image: url('../assets/banner/image.jpg');
    background-size: 100% 100%;
    padding-top: 50px;
    padding-left: 70px;
    display: flex;
    flex-direction: column;
    align-items: start;
    /* animation: slide-in 2s forwards; */
}

.listImg {
    cursor: pointer;
}

.prhead {
    font-size: 20px;
}

.prtext {
    font-size: 12px;
    text-align: start;
    margin-top: 50px;
    line-height: 25px;
}

.ba1img {
    border-radius: 8px;
    margin-top: 50px;
}

.ba1img1 {
    border-radius: 8px;
}

.conList{
    height: 400px;margin-top: 80px;display: flex;flex-direction: column;justify-content: flex-end;
}
.conCoL{
    background-color: #f8f8f8;height: 320px;margin: 0;margin-right: 23px;border-radius: 10px;
}
.CoLleft{
    padding: 0;font-size: 12px;text-align: start;padding: 55px;padding-top: 80px;
}
.CoLbutt{
    width: 105px;height: 27px;border-radius: 5px;border: 1px solid;display: flex;justify-content: center;align-items: center;margin-top: 50px;
}
.CoRightImg{
    width: 100%;margin-top: -50px;height: 100%;margin-right: -55px;
}
.conCoL1{
    background-color: #f8f8f8;height: 320px;margin: 0;margin-left: 23px;border-radius: 10px;
}
.CoRightImg1{
    width: 100%;margin-top: -50px;height: 100%;margin-left: -55px;
}

.headimg{
    width: 100%;
    background-image: url('../assets/product/banner.jpg');
    height: 750px;
    background-size: 100% 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>