import request from './request.js'

// 客服查询
export function getCustomer(params) {
  return request({
    url: '/getCustomer',
    method: 'get',
    params
  })
}

// // 聊天记录查询
// export function getchat(params) {
//   return request({
//     url: '/getchat',
//     method: 'get',
//     params
//   })
// }
