import request from './request.js'

// 用户列表查询
export function userList(params) {
  return request({
    url: '/heavyUser',
    method: 'get',
    params:params,
  })
}

// 用户新增
export function userAdd(data) {
  return request({
    url: '/heavyadd',
    method: 'post',
    data
  })
}


export function recepus(data){
  return request({
    url:'/recepus',
    method:'post',
    data
  })
}

// // 获取一级分类（get请求）
// export function getSendCategorys() {
//     return request({
//         url: '/course/category/getSecondCategorys',
//     })
// }

// // 查询课程（post请求）
// export function courseSearch(data) {
//     return request({
//         url: '/course/search',
//         method: 'post',
//         data
//     })
// }



