<template>
  <div id="app">
    <NavList ref="navlist"></NavList>
    <router-view to="/index" />
    <CopyRight @child-event="childEvent" v-if="this.$route.path !== '/OnlineService' && pdrrouter()"></CopyRight>
    <!-- 左侧固定盒子 -->
    <!-- <div class="ltpobox d-none d-md-block" v-if="this.$route.path !== '/OnlineService' && pdrrouter()">
      <div class="iconlist">
        <img src="./assets/icon/kf.png" alt="" @click="OnlineServiceClick()">
      </div>
      <div class="iconlist">
        <img src="./assets/icon/bj.png" alt="" @click="dialogVisible = true">
      </div>
      <div class="iconlist">
        <el-popover placement="right" width="200" trigger="hover">
          <div style="color: #000;font-size: 20px;font-weight: 700;">{{ onterList.phone }}</div>
          <img src="./assets/icon/phone.png" slot="reference" alt="">
        </el-popover>

      </div>
    </div> -->
    <!--  -->
    <div class="ltpobox d-block d-md-none" v-if="this.$route.path !== '/OnlineService' && pdrrouter()">
      <div class="iconlist">
        <img src="./assets/icon/kf.png" alt="" @click="OnlineServiceClick()">
      </div>
      <div class="iconlist">
        <img src="./assets/icon/bj.png" alt="" @click="OnlineClick">
      </div>
      <div class="iconlist">
        <el-popover placement="right" width="200" trigger="hover">
          <div style="color: #000;font-size: 20px;font-weight: 700;">{{ onterList.phone }}</div>
          <img src="./assets/icon/phone.png" slot="reference" alt="">
        </el-popover>

      </div>
    </div>

    <!-- 在线留言 -->
    <el-dialog title="在线留言" :visible.sync="dialogVisible" width="45%" style="z-index: 10000;">
      <div class="OnlineMessage">
        <div style="font-size: 20px;text-align: start;margin-bottom: 10px;font-weight: 700;">获取尺寸及报价</div>

        <el-form ref="form" :rules="rules1" :model="form" label-width="80px">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="城市" prop="address">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
          <el-form-item label="建筑类型" prop="type">
            <el-radio v-model="form.type" label="0" border>自建房</el-radio>
            <el-radio v-model="form.type" label="1" border>复式楼</el-radio>
            <el-radio v-model="form.type" label="2" border>别墅</el-radio>
          </el-form-item>
          <el-form-item label="房屋层楼" prop="floor">
            <el-radio v-model="form.floor" label="0" border>2楼</el-radio>
            <el-radio v-model="form.floor" label="1" border>3楼</el-radio>
            <el-radio v-model="form.floor" label="2" border>4楼</el-radio>
            <el-radio v-model="form.floor" label="3" border>其他</el-radio>
          </el-form-item>
          <el-form-item label="留言">
            <el-input v-model="form.remarks"></el-input>
          </el-form-item>
          <el-button type="primary" style="width: 50%;" @click="submitForm('form')" :loading="loadingsub">提交</el-button>
        </el-form>
      </div>
    </el-dialog>

  </div>
</template>

<script>
// 验证手机 
let checkPhone = (rule, value, callback) => {
  let reg = /^1[345789]\d{9}$/
  if (!reg.test(value)) {
    callback(new Error('请输入11位手机号'))
  } else {
    callback()
  }
}
// import HomeIndex from './views/HomeIndex.vue'
import NavList from './views/NavList.vue'
import CopyRight from './views/CopyRight.vue'
import { leaveAdd } from './utils/qualifica'
import { otherList } from './utils/other'
export default {
  name: 'app',
  data() {
    return {
      onterList: {},
      dialogVisible: false,
      form: {
        name: '',
        phone: '',
        address: '',
        type: undefined,
        floor: undefined,
        remarks: ''
      },
      rules1: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { type: 'number', validator: checkPhone, message: '请输入11位有效手机号号码', trigger: ['blur', 'change'] }
        ],
        address: [
          { required: true, message: '请输入地址', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请选择建筑类型', trigger: 'change' },
        ],
        floor: [
          { required: true, message: '请选择房屋层楼', trigger: 'change' },
        ],
      },
      loadingsub: false,
    }
  },
  mounted() {
    otherList().then((res) => {
      console.log(res, 'otherList');
      this.onterList = res.data[0]
    })
  },
  components: {
    // HomeIndex
    NavList,
    CopyRight
  },
  methods: {
    pdrrouter() {
      if (this.$route.path.includes("/userList")) {
        return false
      } else {
        return true
      }
    },
    childEvent(e) {
      this.$refs.navlist.toUrlso(e)
    },
    OnlineClick() {
      if (this.$route.path !== '/OnlineMessage') {
        this.$router.push('/OnlineMessage');
      }

    },
    // 在线客服也
    OnlineServiceClick() {
      if (this.$route.path !== '/OnlineService') {
        this.$router.push('/OnlineService');
      }
    },
    submitForm(form) {
      this.loadingsub = true
      this.$refs[form].validate((valid) => {
        if (valid) {
          console.log(123);
          leaveAdd(this.form).then((res) => {
            console.log(res);
            if (res.status == 200) {
              this.$message({
                message: '提交成功',
                type: 'success'
              });
              this.dialogVisible = false
              this.$refs[form].resetFields();
            }
            this.loadingsub = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      })

    }

  }
}
</script>

<style>
/* body {
  overflow: hidden;
} */

::-webkit-scrollbar {
  width: 0px;
}

#app {
  /* cursor: url("./assets/cursor.ico") 34 34, pointer; */
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 auto;
  /* margin-top: 60px; */
}

.ltpobox {
  position: fixed;
  top: 50%;
  right: 30px;
  height: 180px;
  width: 60px;
  background: #0e081a;
  border-radius: 10px;
  z-index: 100000000;
}

.iconlist:hover {
  background-color: #f9b73b;
  cursor: pointer;
}
.el-input__inner {
  background-color: #f5f6f7 !important;
}
.el-textarea__inner{
  background-color: #f5f6f7 !important;
}
input::placeholder {
  color: #12243a !important;
}
textarea::placeholder {
  color: #12243a !important;
}
</style>
