<template>
  <div>
    <div class="Bottombox">
      <div class="container" style="min-height: 333px;">
        <div class=" row hbox" style="">
          <div class=" col-lg-4 col-sm-12 mx-auto lbox" style="">
            <div>
              <img src="../assets/icon/gmzn.png" alt="">
              <div style="" class="gmzntext">购买指南</div>
              <div style="" class="gmznjj">如何购买一部家用电梯?</div>
            </div>
          </div>
          <div class=" col-lg-4 col-sm-12 mx-auto lbox" style="">
            <div>
              <img src="../assets/icon/dzdt.png" alt="">
              <div style="" class="gmzntext">定制电梯</div>
              <div class="gmznjj">点击“定制电梯”设计您的电梯吧。</div>
            </div>
          </div>
          <div class=" col-lg-4 col-sm-12 mx-auto lbox">
            <div>
              <img src="../assets/icon/lxwm.png" alt="">
              <div class="gmzntext">联系我们</div>
              <div class="gmznjj">请拨打400-001-8093咨询方案和报价</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="copybox">
      <div class="container">

        <div class="row habox" style="">
          <div class="col-lg-2 col-sm-12 mx-auto onebox">
            <div style="" class="libox">
              <div style="" class="headText">
                产品中心
              </div>
              <div class="context" style="" v-for="(item, index) in 3" :key="index">
                ZJ魂·S01
              </div>
            </div>
          </div>

          <div class=" col-lg-2 col-sm-12 mx-auto onebox">
            <div class="libod">
              <div class="headText">
                项目案例
              </div>
              <div class="context">
                安装案例
              </div>
              <div class="context">
                国外案例
              </div>
              <div class="context">
                视频案例
              </div>
              <div class="context">
                用户感言
              </div>
            </div>
          </div>
          <div class=" col-lg-2 col-sm-12 mx-auto onebox">
            <div class="libod">
              <div class="headText">
                关于重甲
              </div>
              <div class="context">
                企业愿景
              </div>
              <div class="context">
                企业使命
              </div>
              <div class="context">
                电梯科普
              </div>
              <div class="context">
                常见问题
              </div>
            </div>
          </div>
          <div class=" col-lg-2 col-sm-12 mx-auto onebox">
            <div class="libod" style="align-items: center;">
              <div class="headText">
                联系我们
              </div>
              <div class="context">
                客服电话
              </div>
              <div class="phone">400-001-8093</div>
            </div>
          </div>
          <div class="col-lg-2 col-sm-12 mx-auto onebox" style="margin-top: 120px;">
            <div class="libod">
              <div class="context" style="text-align: start;width: 92px;">
                在线客服
              </div>
              <div @click="OnlineServiceClick" class="rgkfBox" style="">
                <img src="../assets/icon/rgkg.png" alt="" style="width: 20px;height: 20px;">
                <div style="font-size: 12px;">人工客服</div>
              </div>
              <div class="tyztext" style="">
                体验中心
              </div>
              <div class="rgkfBox">
                <img src="../assets/icon/ztcx.png" alt="" style="width: 15px;height: 15px;">
                <div style="font-size: 12px;">展厅查询</div>
              </div>
            </div>
          </div>
        </div>





        <!-- 0.1版本 -->
        <!-- <div class="tops row">
        <div class="lebox col-lg-2 col-md-6 col-12 p-3">
          <img src="../assets/logob.png" alt="" class="logos">
          <div class="boxlist row">

            <div class="col-3">
              <el-popover placement="top-start" width="175" trigger="hover">
                <img :src="'imgs'+onterList.dyUrl" alt="" style="width: 150px;">

                <img slot="reference" src="../assets/icon/icon_douyin.png" alt="">
              </el-popover>

            </div>
            <div class="col-3">
              <el-popover placement="top-start" width="175" trigger="hover">
                <img :src="'imgs'+onterList.wxUrl" alt="" style="width: 150px;">
                <img slot="reference" src="../assets/icon/icon_vx.png" alt="">
              </el-popover>

            </div>
            <div class="col-3 ">
              <el-popover placement="top-start" width="175" trigger="hover" v-if="onterList.sphUrl">
                <img :src="'imgs'+onterList.sphUrl" alt="" style="width: 150px;">
                <img slot="reference" src="../assets/icon/icon_shipinhao.png" alt="">
              </el-popover>
              <img src="../assets/icon/icon_shipinhao.png" alt="" v-else>
            </div>
            <div class="col-3 ">
              <el-popover placement="top-start" width="175" trigger="hover" v-if="onterList.xhsUrl">
                <img :src="'imgs'+onterList.xhsUrl" alt="" style="width: 150px;">
                <img slot="reference" src="../assets/icon/icon_xiaohongshu.png" alt="">
              </el-popover>
              <img src="../assets/icon/icon_xiaohongshu.png" alt="" v-else>
            </div>
            <div class="col-3 ">
            </div>
          </div>
        </div>

        <div class="cenbox col-lg-6 col-md-6 col-12 p-4 d-none d-md-block" style="margin-top: 40px;color: #a8a5ac; cursor: pointer;">
          <div class="row">
            <div class="col-lg-2 col-md-4 p-3 navActives d-lg-block" v-for="(item,index) in navCopyList" :key="index" @click="copynavclick(index)">
              {{item}}
            </div>
          </div>
        </div>

        <div class="rigbox col-lg-4">
          <div class="row">
            <div class="col-12 mt-4">
              <img src="../assets/icon/bottom_smaillogo_one.png" alt="" style="float: left;">
              <div class="ml-2" style="float: left;color: #a8a5ac;font-size: 1vh;">{{onterList.address}}</div>
            </div>
            <div class="col-12 mt-4">
              <img src="../assets/icon/bottom_samillogo_two.png" alt="" style="float: left;">
              <div class="ml-2" style="float: left;color: #a8a5ac;font-size: 1vh;">{{onterList.Details}}</div>
            </div>
            <div class="col-12 mt-4">
              <img src="../assets/icon/bottom_smaillogo_three.png" alt="" style="float: left;">
              <div class="ml-2" style="float: left;color: #a8a5ac;font-size: 1vh;">{{onterList.phone}}</div>
            </div>
          </div>
        </div>

      </div> -->
        <a class="copytext" href="https://beian.miit.gov.cn">{{ onterList.record }}</a>
        <!-- <div class="copytext">鲁ICP备2024089625号-1</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { otherList } from '../utils/other'
export default {
  name: 'CopyRight',
  data() {
    return {
      navCopyList: [
        '产品展示', '安装示例', '新闻资讯', '关于我们', '联系我们'
      ],
      onterList: {}
    }
  },
  mounted() {
    otherList().then((res) => {
      console.log(res, 'otherList');
      this.onterList = res.data[0]
    })
  },
  methods: {
    copynavclick(index) {
      console.log(index + 1);
      this.$emit('child-event', index + 1);
    },
    OnlineServiceClick() {
      if (this.$route.path !== '/OnlineService') {
        this.$router.push('/OnlineService');
      }
    },
  }
}
</script>

<style>

.Bottombox {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  min-height: 250px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.hbox {
  min-height: 333px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lbox {
  font-size: 18px;
  color: #9d9da0;
  line-height: 34px;
}

.gmzntext {
  font-size: 20px;
  color: #454442;
  margin-top: 12px;
}

.gmznjj {
  font-size: 17px;
  color: #616161;
}


.copybox {
  width: 100%;
  min-height: 200px;
  background-color: #262626;
}

.logos {
  width: 200px;
}

.boxlist {
  margin-top: 20px;
}

.navActives:hover {
  color: #fff;
}

.habox {
  min-height: 333px;
  display: flex;
  justify-content: center;
}

.onebox {
  font-size: 18px;
  color: #9d9da0;
  line-height: 34px;
  margin-top: 80px;
}

.libod {
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: center;
}

.headText {
  font-size: 15px;
  color: #fff;
  font-weight: 900;
}

.context {
  color: #fff;
  font-size: 11px;
}

.phone {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.rgkfBox {
  cursor: pointer;
  width: 93px;
  height: 34px;
  background-color: #111111;
  border: 2px solid #060606;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.tyztext {
  color: #fff;
  font-size: 11px;
  text-align: start;
  width: 92px;
}
</style>