<template>
  <div style="margin-top:75px;">
    <div class="w-100 headimg4">
      <!-- <img class="w-100" src="../assets/product/banner.jpg" alt=""> -->
      <div style="margin-top: 320px;font-size: 30px;">关于我们</div>
      <div style="margin-top: 20px;font-size: 20px;">ABOUT US</div>
    </div>
    <div class="ProductBox container " style="width: 55%;margin-bottom: 50px;">
      <div class="row">
        <div class="col-6 col-lg-6 col-md-6 col-sm-12">
          <div class="jjhead" style="">
            <div class="jjline" style=""></div>
            <div>公司介绍</div>
          </div>
          <div class="jjtext" style="">
            1853年在纽约水晶宫举行的世界博览会上，伊莱沙·格雷夫斯·奥的斯向世人展示了人类历史
            上第一部安全升降梯，彻底改写人类使用升降工具的历史。21世纪初，以芬兰、瑞士为首的
            技术派登顶电梯金字塔的塔尖。重甲创始人在经过多年的欧洲项目合作、生产研发经验后，
            将先进技术和原班人马引入中国，于2017年在山东济南正式创立山东重甲，致力成为全球
            电梯科技行业的领航者，携手全球合作伙伴让便捷、智能、安全的电梯通行体验遍布世界
            每一个角落，为人类的城市生活注入无限活力与可能。
          </div>
        </div>
        <div class="col-5 col-lg-5 col-md-5 col-sm-12">
          <img class="bannerimg" style="" src="../assets/aboutus/a2.jpg" alt="">
        </div>
      </div>

    </div>
    <div class="w-100" style="position: relative;">
      <img class="w-100" src="../assets/aboutus/a3.jpg" alt="">
      <div style="position: absolute;top: 27%;color: #fff;width: 100%;">
        <div style="display: flex;justify-content: space-around;align-items: center;">
          <div
            style="display: flex;flex-direction: column;align-items: center;justify-content: space-around;height: 180px;">
            <img src="../assets/aboutus/b1.png" alt="" style="width: 25px;">
            <div>成立时间</div>
            <div style="width: 30px;height: 4px;background-color: #fff;border-radius: 5px;"></div>
            <div style="display: flex;align-items: flex-end;">
              <div style="font-size: 30px;">2017</div>
              <div style="margin-left: 2px;margin-bottom: 5px;">年</div>
            </div>
          </div>
          <div
            style="display: flex;flex-direction: column;align-items: center;justify-content: space-around;height: 180px;">
            <img src="../assets/aboutus/b2.png" alt="" style="width: 25px;">
            <div>服务团队</div>
            <div style="width: 30px;height: 4px;background-color: #fff;border-radius: 5px;"></div>
            <div style="display: flex;align-items: flex-end;">
              <div style="font-size: 30px;">300</div>
              <div style="margin-left: 2px;margin-bottom: 5px;">+</div>
            </div>
          </div>
          <div
            style="display: flex;flex-direction: column;align-items: center;justify-content: space-around;height: 180px;">
            <img src="../assets/aboutus/b3.png" alt="" style="width: 25px;">
            <div>服务站点</div>
            <div style="width: 30px;height: 4px;background-color: #fff;border-radius: 5px;"></div>
            <div style="display: flex;align-items: flex-end;">
              <div style="font-size: 30px;">5000</div>
              <div style="margin-left: 2px;margin-bottom: 5px;">+</div>
            </div>
          </div>
          <div
            style="display: flex;flex-direction: column;align-items: center;justify-content: space-around;height: 180px;">
            <img src="../assets/aboutus/b4.png" alt="" style="width: 25px;">
            <div>全国覆盖</div>
            <div style="width: 30px;height: 4px;background-color: #fff;border-radius: 5px;"></div>
            <div style="display: flex;align-items: flex-end;">
              <div style="font-size: 30px;">16000</div>
              <div style="margin-left: 2px;margin-bottom: 5px;">+</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ProductBox container " style="width: 55%;margin-bottom: 50px;">
      <div class="row">
        <div class="col-12">
          <div class="bottboxhead" style="">
            <div class="botlin" style=""></div>
            <div>重甲认证</div>
          </div>

          <div class="w-100" style="margin-top: 0px;">
            <div class="Listbox row" style="margin-bottom: 10px;margin-top: 0px;">
              <div class="col-lg-6 col-md-6 col-sm-12 p-3 mt-3">
                <div class="xwbox w-100 h-100" style="position: relative;">
                  <img class="xwimg" src="../assets/aboutus/a4.jpg" alt="">
                  <div style="position: absolute;top: 20%;">
                    <div style="display: flex;flex-direction: column;align-items: center;
                    justify-content: space-around;width: 500px;">
                      <img src="../assets/aboutus/c1.png" alt="" style="width: 40px;">
                      <div style="font-size: 15px;color: #fff;margin-top: 5%;">
                        高质量认证
                      </div>
                      <div style="font-size: 8px;width: 260px;color: #fff;margin-top: 5%;font-weight: 100;">
                        重甲全新智能控制系统符合GB/T12350-2009认证标准和TSGT7007-2016认证要求，具备超重保护液压油路防爆阀防坠落等多项功能。
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 p-3 mt-3">
                <div class="xwbox w-100 h-100" style="position: relative;">
                  <img class="xwimg" src="../assets/aboutus/a5.jpg" alt="">
                  <div style="position: absolute;top: 20%;">
                    <div style="display: flex;flex-direction: column;align-items: center;
                    justify-content: space-around;width: 500px;">
                      <img src="../assets/aboutus/c2.png" alt="" style="width: 40px;">
                      <div style="font-size: 15px;color: #fff;margin-top: 5%;">
                        严选核心部件
                      </div>
                      <div style="font-size: 8px;width: 300px;color: #fff;margin-top: 5%;font-weight: 100;">
                        超高配置组合,达到最佳的运行效果。新一代主机门机和控制系统让安全、舒适、静音成为我们代名词多重安全部件环环相扣,安全自然不言而喻
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 p-3 mt-3">
                <div class="xwbox w-100 h-100" style="position: relative;">
                  <img class="xwimg" src="../assets/aboutus/a6.jpg" alt="">
                  <div style="position: absolute;top: 20%;">
                    <div style="display: flex;flex-direction: column;align-items: center;
                    justify-content: space-around;width: 500px;">
                      <img src="../assets/aboutus/c3.png" alt="" style="width: 40px;">
                      <div style="font-size: 15px;color: #fff;margin-top: 5%;">
                        定制解决方案
                      </div>
                      <div style="font-size: 8px;width: 300px;color: #fff;margin-top: 5%;font-weight: 100;">
                        重甲通过多样化解决方案和成熟的土建安装经验，充分利用每一寸空间实现尺寸和个性化定制，在有限的并道空间内设计更合理的的乘梯空间实现空间感与舒适度的完美匹配。为您带来更好的乘梯体验
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 p-3 mt-3">
                <div class="xwbox w-100 h-100" style="position: relative;">
                  <img class="xwimg" src="../assets/aboutus/a7.jpg" alt="">
                  <div style="position: absolute;top: 20%;">
                    <div style="display: flex;flex-direction: column;align-items: center;
                    justify-content: space-around;width: 500px;">
                      <img src="../assets/aboutus/c4.png" alt="" style="width: 40px;">
                      <div style="font-size: 15px;color: #fff;margin-top: 5%;">
                        电梯维保服务
                      </div>
                      <div style="font-size: 8px;width: 300px;color: #fff;margin-top: 5%;font-weight: 100;">
                        重甲作为中国家用电梯(别墅)十佳品牌基于遍布全国的维保工程师团队和行业领先的维保体系为您提供豪华酒店管家式的五星级服务确保电梯全生命周期过程中,您随时随地都能获得便捷而专业的服务
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>

      </div>

    </div>
  </div>

  <!-- <div style="margin-top: 130px;margin-bottom: 270px;">
    <div class="ProductBox container">
      <div class="producthead" style="">
        <div style="font-style: italic;">公司简介</div>
        <div class="textbor"></div>
      </div>
      <div class="row mt-5">
        <div style="text-align: start;font-size: 18px;">
          山东重甲重工机械有限公司，成立于2017年，公司总部位于风景秀丽的泉城济南，是一家拥有国际知名品牌的中国智能科技企业，拥有技术精湛的专家级团队，集聚了多个领域的一流权威人才，代表着国内智能机械行业的应用方向和研发水平，科研力量雄厚。
        </div>
        <div style="text-align: start;font-size: 15px;" class="mt-5">
          旗下有：久康科技、济南清新金属材料、卡优媞(山东)美容护肤、山东沃悦智能科技、山东重甲机械设备、山东爵象智能科技、杭州雏鹰智联科技等多家子公司，旗下更有“久康、尚恩、幻侣、米系、欧基、爵象、沃悦”等多个知名品牌。以满足客户现有需求、引导未来应用趋势为中心，将产品的智能化、机能性、外观造型、品质作为一个整体有机结合，进行产品的系列化研发，以先进智能机械技术服务于生活。
        </div>
        <div style="text-align: start;font-size: 15px;" class="mt-5">
          企业坚持以人为本，任人唯贤，人适其位，位适其人的人才观，团结、务实、敬业、高效、拼搏、创新的企业精神，历经十年的坚实运营，如今的久康科技已成长为中国智能科技最具经营规模及影响力的优秀企业之一，致力成为全球最专业的智能科技产品服务商。
        </div>
      </div>
      <div id="container" class="mt-5" style="min-height: 400px;"></div>
    </div>
    
  </div> -->
</template>

<script>
export default {
  mounted() {
    // /* eslint-disable */
    // var map = new qq.maps.Map(document.getElementById("container"), {
    //   center: new qq.maps.LatLng(36.68255911425377, 116.98446042824173),      // 地图的中心地理坐标。
    //   zoom: 16                                               // 地图的中心地理坐标。
    // });

    // //添加定时器
    // setTimeout(function () {
    //   var marker = new qq.maps.Marker({
    //     position: new qq.maps.LatLng(36.68255911425377, 116.98446042824173),
    //     animation: qq.maps.MarkerAnimation.DROP,
    //     map: map
    //   });
    //   //marker.setAnimation(qq.maps.Animation.DROP);
    // }, 2000);
  }

}
</script>

<style>
.headimg4 {
  width: 100%;
  background-image: url('../assets/aboutus/a1.jpg');
  height: 750px;
  background-size: 100% 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jjhead {
  width: 100%;
  font-size: 14px;
  display: flex;
  height: 25px;
  align-items: center;
}

.jjline {
  width: 2px;
  height: 10px;
  background-color: black;
  margin-right: 5px;
}

.jjtext {
  font-size: 12px;
  font-weight: lighter;
  text-align: start;
  margin-top: 20px;
  line-height: 23px;
  color: black;
}

.bannerimg {
  width: 80%;
  height: 80%;
  margin-top: 5%;
}

.bottboxhead {
  width: 100%;
  font-size: 14px;
  display: flex;
  height: 25px;
  align-items: center;
}

.botlin {
  width: 2px;
  height: 10px;
  background-color: black;
  margin-right: 5px;
}
</style>