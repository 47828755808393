import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeIndex from '../views/HomeIndex.vue'
import ProductDisplay from '../views/ProductDisplay.vue'
import AboutUs from '../views/AboutUs.vue' //关于我们
import InstallationCase from '../views/InstallationCase.vue' //安装案例
import NewsInformation from '../views/NewsInformation.vue' //新闻资讯
import ContactUs from '../views/ContactUs.vue' //联系我们
import OnlineMessage from '../components/OnlineMessage.vue' //在线留言小屏幕
import HelloWorld from '../components/HelloWorld.vue' //
import OnlineService from '../components/OnlineService.vue' //在线留言小屏幕
import UserList from '../components/UserList.vue' //UserList


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeIndex',
    component: HomeIndex,
    mode: 'history'
  },
  {
    path: '/ProductDisplay',
    name: 'ProductDisplay',
    component: ProductDisplay
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/NewsInformation',
    name: 'NewsInformation',
    component: NewsInformation
  },
  {
    path: '/InstallationCase',
    name: 'InstallationCase',
    component: InstallationCase
  },
  {
    path: '/OnlineMessage',
    name: 'OnlineMessage',
    component: OnlineMessage
  },
  {
    path: '/HelloWorld',
    name: 'HelloWorld',
    component: HelloWorld
  },
  {
    path: '/OnlineService',
    name: 'OnlineService',
    component: OnlineService
  },
  {
    path: '/UserList',
    name: 'UserList',
    component: UserList
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
