<template>
  <div style="margin-top:75px;">
    <div class="w-100 headimg2">
      <!-- <img class="w-100" src="../assets/product/banner.jpg" alt=""> -->
      <div style="margin-top: 320px;font-size: 30px;">新闻资讯</div>
      <div style="margin-top: 20px;font-size: 20px;">NEWS INFORMATION</div>
    </div>
    <div class="ProductBox container" style="width: 50%;margin-bottom: 0px;">
      <div class="producthead prhead">
        <div>新闻资讯</div>
        <div class="xiah"></div>
      </div>
      <div class="w-100 smallhead">
        重甲电梯从21世纪到2024年的发展历程概括
      </div>

      <div class="w-100">
        <div class="Listbox row" style="margin-bottom: 100px">
          <div class="col-5 contLeft">
            <div class="leftmb">
              <img class="lefimg" src="../assets/newsinformation/i1.png" alt="">
            </div>

            <div class="rbox" style="margin-top: 186px;">
              <div style="width: 100%;font-weight: 800;text-align: end;padding-right: 30px;">2014年</div>
              <div style="width: 100%;font-weight: 500;text-align: end;padding-right: 30px;font-size: 12px;">
                <div>创始人陆续开始将</div>
                <div>先进技术和产品经验引入中国</div>
              </div>
            </div>

            <div class="leftmb" style="margin-top: 131px">
              <img class="lefimg" style="width: 60px;height: 60px;" src="../assets/newsinformation/i4.png" alt="">
            </div>

            <div class="rbox" style="margin-top: 185px;">
              <div style="width: 100%;font-weight: 800;text-align: end;padding-right: 30px;">2020年</div>
              <div style="width: 100%;font-weight: 500;font-size: 12px;text-align: end;padding-right: 30px;">
                <div>重甲家用电梯（别墅）共在全国</div>
                <div>覆盖达16000台</div>
              </div>
            </div>

            <div class="leftmb" style="margin-top: 155px">
              <img class="lefimg" style="width: 52.5px;height: 49.5px;" src="../assets/newsinformation/i2.png" alt="">
            </div>
          </div>
          <div class="col-2 centlien" style="">
            <div class="square"></div>
            <div class="lines"></div>
            <div class="square"></div>
            <div class="lines" style="height: 227px;"></div>
            <div class="square"></div>
            <div class="lines" style="height: 246px;"></div>
            <div class="square"></div>
            <div class="lines" style="height: 228px;"></div>
            <div class="square"></div>
            <div class="lines" style="height: 200px;"></div>
          </div>
          <div class="col-5 rigtBox">
            <div class="rbox">
              <div style="width: 150px;font-weight: 800;">21世纪初</div>
              <div style="width: 200px;font-weight: 500;font-size: 12px;">
                <div>重甲创始人开始欧洲项目合作</div>
                <div>共同生产研发。</div>
              </div>
            </div>

            <div class="leftmb" style="margin-top: 105px">
              <img class="lefimg" style="width: 72px;height: 46.5px;" src="../assets/newsinformation/i3.png" alt="">
            </div>

            <div class="rbox" style="margin-top: 212px;">
              <div style="width: 150px;font-weight: 800;">2017年</div>
              <div style="width: 200px;font-weight: 500;font-size: 12px;">
                <div>正式创立山东重甲，从开始迈出</div>
                <div>全球性的步伐。</div>
              </div>
            </div>

            <div class="leftmb" style="margin-top: 86px">
              <img class="lefimg" style="width: 60px;height: 60px;" src="../assets/newsinformation/i5.png" alt="">
            </div>

            <div class="rbox" style="margin-top: 245px;">
              <div style="width: 150px;font-weight: 800;">2024年</div>
              <div style="width: 210px;font-weight: 500;font-size: 12px;">
                <div>山东重甲获评为中国家用电梯（别墅）</div>
                <div>十佳品牌。</div>
              </div>
            </div>

            

          </div>
        </div>
      </div>

    </div>
  </div>
  
</template>

<script>
import { getList } from '../utils/NewsInformation'
export default {
  data() {
    return {
      newsList:[],
      loading:null
    }
  },
  mounted() {
    this.loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    //获取案例列表
    this.alList()

  },
  methods: {
    alList() {
      getList({
        state:0
      }).then((res) => {
        this.loading.close();
        // console.log(res);
        this.newsList=res.data
      })
    }
  }

}
</script>

<style>
.headimg2{
    width: 100%;
    background-image: url('../assets/newsinformation/i0.jpg');
    height: 750px;
    background-size: 100% 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.xwbox{
  min-height: 150px;
  /* border: 1px solid red; */
  background: #FBFBFB;
  transition: transform 0.3s ease; /* 动画效果 */
  cursor: pointer;
}
.xwbox:hover {
  transform: scale(1.1); /* 放大到1.2倍 */
}
.xwimg{
  width: 100%;
  /* */
}
.time{
  color: #0052D9;float: left;font-size: 18px;text-align: start;padding-left: 10px;
}
.xwHead{
  font-size: 20px;
  /* float: left; */
  text-align: start;
  width: 90%;
  margin: 0 auto;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2b2e2e;
}
.xwtitle{
  width: 90%;
  font-size: 15px;
  color: #2b2e2e;
  margin: 0 auto;
  text-align: start;
  font-weight: 400;
  margin-bottom: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.square {
  width: 20px;
  height: 20px;
  background-color: #3498db;
  transform: rotate(45deg);
  transform-origin: center; /* 设置旋转的基点为中心 */
  border-radius: 5px;
  background-image: linear-gradient(135deg, #2e70fd, #5dbefd);
}

.lines{
  width: 2px;
  height: 250px;
  background-color: #6dd8fb;
}

.xiah{
  width: 30px;height: 1px;background-color: black;margin-top: 10px;
}
.smallhead{
  font-size: 12px;margin-top: 20px;
}
.contLeft{
  height: 100%;display: flex;flex-direction: column;align-items: flex-end;
}
.leftmb{
  width: 195px;height: 90px;border: 4px solid #ebebeb; border-radius: 80px;display: flex;justify-content: center;align-items: center;
}
.lefimg{
  width: 112.5px;height: 45px;
}
.centlien{
  height: 100%;display: flex;flex-direction: column;align-items: center;
}
.rigtBox{
  height: 100%;display: flex;flex-direction: column;align-items: flex-start;
}
.rbox{
  height: 116.25px;background-color: #f3f5f7;padding-left: 40px;display: flex;flex-direction: column;align-items: start;font-size: 17px;text-align: start;color: #636363;font-weight: 500;min-width: 280px;height: 107.25px;justify-content: space-evenly;border-radius: 10px;
}
</style>