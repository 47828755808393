<template>
    <div class="container" style="min-height: 668px;">
        <el-table class="" :data="tableData" border style="width: 100%;margin-top: 100px;">
            <el-table-column fixed prop="userkey" label="key">
            </el-table-column>
            <el-table-column fixed prop="identity" label="connected">
                <template slot-scope="scope">
                    <!-- <div>{{ scope.row.state==0?'离线':'在线'}}</div> -->
                    <el-tag type="danger" v-if="scope.row.state == 0">离线</el-tag>
                    <el-tag type="success" v-if="scope.row.state == 1">在线</el-tag>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small">连接</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog :title="`客户：` + dqkey" :visible.sync="UserSxx" width="30%" :fullscreen="true" :append-to-body=false>
            <!-- <header class="headers1">客户：{{ dqkey }}</header> -->
            <div class="container" style="background-color: azure;">
                <div class="row">
                    <div class="hgroups" id="mianscroll1" style="overflow-y: scroll;height: 700px;">
                        <div v-for="(item, index) in messages" :key="index">
                            <div class="user" v-if="item.identity == 1">
                                <div class='textstyle'>{{ item.text }}</div>
                                <img style='margin-left:10px;width: 50px;' src="../assets/logo.png" alt="">
                            </div>
                            <div class="system" v-if="item.identity == 0">
                                <i class="el-icon-user" style='margin-right:10px'></i>
                                <div class='textstyle'>{{ item.text }}</div>
                                <!-- <i class="el-icon-user-solid" style='margin-right:10px'></i> -->

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="" style="position: fixed;bottom: 0px;background-color: #fff;width: 100%;height: 100px;">
                <div class="footers" style="margin: 0 auto;max-width: 900px;">

                    <el-input type="textarea" class="ipt" placeholder="请输入内容" v-model="newMessage"
                        @keyup.enter.native="sendMsgToServer"></el-input>

                    <el-button type="success" @click="sendMsgToServer">发送</el-button>

                </div>
            </div>

        </el-dialog>
    </div>

</template>

<script>
import io from 'socket.io-client';
import { userList, recepus } from '../utils/user.js'
import { chatadd, getchat } from '../utils/chathistory'
import { getCustomer } from '../utils/customerservice'
var socket=null
// const socket = io(process.env.VUE_APP_IO_URL_SOCK);
export default {
    data() {
        return {
            messages: [],
            newMessage: '',
            tableData: [],
            dqkey: '',
            UserSxx: false,
            userid: ''
        }
    },
    methods: {
        scrollToBottom: function () {
            this.$nextTick(() => {
                let scrollEl = document.getElementById('mianscroll1');
                // console.log(scrollEl.scrollHeight, 'scrollEl');
                // scrollEl.scrollTo({ top: scrollEl.scrollHeight });
                scrollEl.scroll({ top: scrollEl.scrollHeight, left: 0, behavior: 'smooth' });
            });
        },
        sendMsgToServer() {
            if (this.newMessage.length > 0) {
                chatadd({
                    memberId: this.userid,
                    userkey: this.dqkey,
                    text: this.newMessage,
                    identity: 1,
                    kfname: this.$route.query.key
                    // 'kfc1'
                }).then(() => {
                    socket.emit(this.$route.query.key, {
                        key: this.dqkey,
                        conter: this.newMessage
                    });
                    // this.messages.push({
                    //     text: data,
                    //     identity: 1
                    // })
                    this.newMessage = '';


                })

            } else {
                this.$notify({
                    title: '警告',
                    message: '不可发送空消息',
                    type: 'warning'
                });
            }
        },
        handleClick(row) {
            recepus({
                userkey: row.userkey,
                receivingid: this.$route.query.key
            }).then((res) => {
                console.log(res);
                if (res.code == 400) {
                    this.$notify({
                        title: '警告',
                        message: '已被其他客服接入',
                        type: 'warning'
                    });
                } else {
                    this.$notify({
                        title: '成功',
                        message: '接入成功',
                        type: 'success'
                    });
                    this.userList()


                    socket.off('eventName', this.dqkey);
                    console.log(row);
                    this.userid = row.id
                    this.dqkey = row.userkey
                    // this.messages = row.conter
                    this.messages = []

                    getchat({
                        uid: this.userid
                    }).then((e) => {
                        // console.log(e);
                        this.messages = e.data
                        this.UserSxx = true
                        this.scrollToBottom()
                    })

                    socket.on(this.$route.query.key, (data) => {
                        console.log('Received message from server:', data);
                        this.messages.push({
                            text: data,
                            identity: 0
                        });
                        // 处理来自服务器的消息
                        this.scrollToBottom()
                    });

                    socket.on(this.dqkey, (data) => {
                        console.log('Received message from server:', data);
                        this.messages.push({
                            text: data,
                            identity: 1
                        });
                        // 处理来自服务器的消息
                        this.scrollToBottom()
                    });
                }

            })



        },
        userList() {
            // { state: 0 }
            userList({
                state: 1,
                receivingid: this.$route.query.key
            }).then((response) => {
                // console.log(response.data);
                this.tableData = response.data
            })
        }
    },
    created() {

        console.log(this.$route.query.key);
        getCustomer({
            CustomerId: this.$route.query.key
        }).then((res) => {
            // console.log(res.data);
            if (res.data.length) {
                socket = io(process.env.VUE_APP_BASE_APIs, {
                    // 可以在这里添加额外的连接选项
                });

                socket.on('connect', () => {
                    this.userList()
                    socket.emit('authenticate', { userId: this.$route.query.key, password: 'pass456' });
                    socket.on('authenticated', (data) => {
                        console.log(data);
                        console.log('您好,' + res.data[0].CustomerName + '!!!');


                        // 监听来自服务器的消息  #kfc1 this.$route.query.key
                        // socket.on(this.$route.query.key, (data) => {
                        //     // console.log('Received message from server:', data);
                        //     this.messages.push({
                        //         text: data,
                        //         identity: 0
                        //     });

                        // });

                        socket.on('listsrc', () => {
                            this.userList()
                        });

                    })
                })


            } else {
                console.log('没有这个客服信息');
            }
        })





    },
    disconnectSocket() {
        // 断开 socket 连接
        if (socket) {
            socket.disconnect();
        }
    },
}
</script>



<style>
/* 添加一个自定义类来覆盖滚动条样式 */
.el-dialog.is-fullscreen {
    overflow: hidden !important;
}

.headers1 {

    /*标题设置*/

    height: 80px;
    /*高*/

    background-color: aquamarine;
    /*背景色*/

    line-height: 100px;
    /*行高,控制上下居中*/

    text-align: center;

    position: fixed;
    top: 0px;
    width: 100%;
    font-size: 25px;
    font-weight: 900;
    /*控制左右居中*/

}

.hgroups {

    /* 内容区样式*/
    /* width: 50%; */
    /* height: 300px; */

    background-color: azure;
    /* border: 1px solid red; */
    border-radius: 20px;
    min-height: 40%;
    max-height: 50%;
    overflow: auto;
    padding: 20px;
    /* overflow-y: scroll; */
    /*聊天内容过多时自动添加滚动条*/

}

.footers {

    /* 底部区样式*/

    display: flex;
    /* 控制底部框框和按钮在一排 横着显示的*/

}

.ipt {

    margin-right: 20px;
    /* 底部区框框和按钮中间的间隙*/

}

.user {
    /* 用户说话样式*/

    float: right;
    /* 用户说话自动靠右*/

    clear: both;
    /* 用户说话左边不能有东西*/

    display: flex;
    /* 头像和说话并排显示*/

    align-items: center;
    /* 头像在说话高度中间对齐*/
    margin-top: 10px;

}

.textstyle {
    /* 气泡框绿色背景样式*/

    padding: 10px;

    background-color: rgb(128, 210, 98);

    border-radius: 10px;

    max-width: 500px;

    word-wrap: normal;
    /* 说话太长自动换行*/

    line-height: 24px;

}

.system {
    /* 系统说话样式*/

    float: left;

    clear: both;

    display: flex;

    align-items: center;
    margin-top: 10px;

}
</style>