import request from './request.js'

// 聊天记录新增
export function chatadd(data) {
  return request({
    url: '/chatadd',
    method: 'post',
    data
  })
}

// 聊天记录查询
export function getchat(params) {
  return request({
    url: '/getchat',
    method: 'get',
    params
  })
}
