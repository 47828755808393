<template>
    <div style="margin-top: 90px;margin-bottom: 100px;">
        <div class="OnlineMessage container">

            <div class="producthead mt-5">
                <div style="font-weight: 700;font-size: 25px;">获取尺寸及报价</div>
                <div class="textbor"></div>
            </div>
            <el-form ref="form" :rules="rules1" :model="form" label-width="80px" class="mt-5">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="电话" prop="phone">
                    <el-input v-model="form.phone"></el-input>
                </el-form-item>
                <el-form-item label="城市" prop="address">
                    <el-input v-model="form.address"></el-input>
                </el-form-item>
                <el-form-item label="建筑类型" prop="type">
                    <el-radio v-model="form.type" label="1" border>自建房</el-radio>
                    <el-radio v-model="form.type" label="2" border>复式楼</el-radio>
                    <el-radio v-model="form.type" label="3" border>别墅</el-radio>
                </el-form-item>
                <el-form-item label="房屋层楼" prop="hownum">
                    <el-radio v-model="form.hownum" label="1" border>2楼</el-radio>
                    <el-radio v-model="form.hownum" label="2" border>3楼</el-radio>
                    <el-radio v-model="form.hownum" label="3" border>4楼</el-radio>
                    <el-radio v-model="form.hownum" label="4" border>其他</el-radio>
                </el-form-item>
                <el-form-item label="留言">
                    <el-input v-model="form.message"></el-input>
                </el-form-item>
                <el-button type="primary" style="width: 50%;" @click="OnlineClick">提交</el-button>
            </el-form>
        </div>
    </div>
</template>

<script>
// 验证手机 
let checkPhone = (rule, value, callback) => {
    let reg = /^1[345789]\d{9}$/
    if (!reg.test(value)) {
        callback(new Error('请输入11位手机号'))
    } else {
        callback()
    }
}
export default {
    /* eslint-disable */
    name: 'OnlineMessage',
    data() {
        return {
            form: {
                name: '',
                phone: '',
                address: '',
                type: 1,
                hownum: 1,
                message: ''
            },
            rules1: {
                name: [
                    { required: true, message: '请输入姓名', trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { type: 'number', validator: checkPhone, message: '请输入11位有效手机号号码', trigger: ['blur', 'change'] }
                ],
                address: [
                    { required: true, message: '请输入地址', trigger: 'blur' },
                ],
                type: [
                    { required: true, message: '请选择建筑类型', trigger: 'change' },
                ],
                hownum: [
                    { required: true, message: '请选择房屋层楼', trigger: 'change' },
                ],
            }
        }
    },
    methods: {
        OnlineClick() {
            this.$router.push('/');
        }
    }
}
</script>

<style lang="less" scoped>

.el-radio.is-bordered {
    width: 80%;
    float: left;
}

.el-form-item__content {
    display: flex;
    flex-direction: column;
    align-items: start;

}

.el-radio.is-bordered+.el-radio.is-bordered {
    margin-left: 0 !important;
    margin-top: 10px;
}
</style>