import request from './request.js'

// 重甲安新闻资讯列表查询
export function getList(params) {
  return request({
    url: '/newsList',
    method: 'get',
    params
  })
}

// // 聊天记录查询
// export function getchat(params) {
//   return request({
//     url: '/getchat',
//     method: 'get',
//     params
//   })
// }
