import request from './request.js'

// 重甲资质列表查询
export function getList(params) {
  return request({
    url: '/qualificaList',
    method: 'get',
    params
  })
}

//轮播图查询请求
export function carouselList(params) {
  return request({
    url: '/carouselList',
    method: 'get',
    params
  })
}
// // 聊天记录查询
// export function getchat(params) {
//   return request({
//     url: '/getchat',
//     method: 'get',
//     params
//   })
// }



//在线留言添加
export function leaveAdd(data) {
  return request({
    url: '/leaveAdd',
    method: 'post',
    data
  })
}



