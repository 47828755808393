<template>
    <div class="chat" style="margin-top: 150px;margin-bottom: 100px;color: black;">
        <!-- {{ userId }} -->
        <!-- <div class="messages" ref="messages">
            <div v-for="(item, index) in messages" :key="index">
                {{ item.identity == 0 ? '我' : '客服' }}:{{ item.text }}
            </div>
        </div> -->
        <header class="headers">重甲电梯客服</header>
        <div class="container" id="mianscroll" style="background-color: azure;">
            <div class="row">
                <div class="hgroups">
                    <div v-for="(item, index) in messages" :key="index">
                        <div v-if="item.text != undefined">
                            <div class="user" v-if="item.identity == 0">
                                <div class='textstyle'>{{ item.text }}</div><i class="el-icon-user"
                                    style='margin-left:10px'></i>
                            </div>
                            <div class="system" v-if="item.identity == 1">
                                <img style='margin-right:10px;width: 50px;' src="../assets/logo.png" alt="">

                                <!-- <i class="el-icon-user-solid" style='margin-right:10px'></i> -->
                                <div class='textstyle'>{{ item.text }}</div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>
        <div class="" style="position: fixed;bottom: 0px;background-color: #fff;width: 100%;height: 100px;">
            <form class="footers" style="margin: 0 auto;max-width: 900px;">

                <el-input type="textarea" @keyup.enter.native="sendMsgToServer" @focus="scrollToBottom" class="ipt"
                    placeholder="请输入内容" v-model.trim="newMessage"></el-input>

                <el-button type="success" @click="sendMsgToServer">发送</el-button>

            </form>
        </div>
        <!-- <form @submit.prevent="sendMsgToServer">
            <input type="text" v-model.trim="newMessage" placeholder="请输入消息内容" />
            <button type="submit">发送</button>
        </form> -->



    </div>
</template>
<script>
import io from 'socket.io-client';
import { userAdd, userList } from '../utils/user'
import { chatadd, getchat } from '../utils/chathistory'
export default {
    name: 'OnlineService',
    data() {
        return {
            messages: [],
            newMessage: '',
            socket: null,
            userId: ''
        }
    },
    watch: {
        myObject: {
            messages(newValue, oldValue) {
                console.log('myObject changed:', newValue, oldValue);
                // 执行相关逻辑
                this.$nextTick(() => {
                    let scrollEl = document.getElementById('mianscroll');
                    // console.log(scrollEl.scrollHeight,'scrollEl');
                    // scrollEl.scrollTo({ top: scrollEl.scrollHeight });
                    window.scroll({ top: scrollEl.scrollHeight, left: 0, behavior: 'smooth' });
                });
            },
            deep: true // 启用深度监听
        }
    },
    mounted() {
        this.connectSocket()
    },
    methods: {
        scrollToBottom: function () {
            this.$nextTick(() => {
                let scrollEl = document.getElementById('mianscroll');
                // console.log(scrollEl.scrollHeight,'scrollEl');
                // scrollEl.scrollTo({ top: scrollEl.scrollHeight });
                window.scroll({ top: scrollEl.scrollHeight, left: 0, behavior: 'smooth' });
            });
        },
        //   发送到服务器
        sendMsgToServer() {
            if (this.newMessage.length > 0) {
                userList({
                    userkey: sessionStorage.getItem('Userkey')
                }).then((res) => {
                    console.log(res);

                    chatadd({
                        memberId: sessionStorage.getItem('UserId'),
                        userkey: sessionStorage.getItem('Userkey'),
                        text: this.newMessage,
                        identity: 0,
                        kfname: res.data[0].receivingid
                    }).then(() => {
                        // this.userId
                        // if (res.data[0].receivingid == null) {
                        //     console.log(123);
                        // } else {

                        // }

                        this.socket.emit(sessionStorage.getItem('Userkey'), {
                            conter: this.newMessage,
                            key: res.data[0].receivingid
                        });

                        this.messages.push({
                            text: this.newMessage,
                            identity: 0
                        });
                        this.newMessage = '';
                        this.scrollToBottom()
                    })
                })



            }

        },
        connectSocket() {
            console.log(sessionStorage.getItem('Userkey'));
            // 使用环境变量中定义的 Socket.IO 服务器 URL 初始化 socket 连接
            this.socket = io(process.env.VUE_APP_BASE_APIs, {
                // 可以在这里添加额外的连接选项
            });

            // 监听 connect 事件来判断连接是否成功
            this.socket.on('connect', () => {
                if (sessionStorage.getItem('Userkey')) {
                    this.userId = sessionStorage.getItem('Userkey')
                    this.socket.emit('authenticate', { userId: this.userId, password: 'pass456' });
                    this.socket.on('authenticated', (data) => {
                        // console.log('Permanent ID:', data.permanentId);
                        this.userId = data.permanentId

                        this.messages.push({
                            text: data.text,
                            identity: 1
                        });


                    });

                    getchat({
                        uid: sessionStorage.getItem('UserId')
                    }).then((e) => {

                        // this.messages = e.data
                        this.messages = e.data.concat(this.messages);
                        this.scrollToBottom()
                        console.log(this.messages);
                    })



                    this.scrollToBottom()

                    this.socket.on(sessionStorage.getItem('Userkey'), (data) => {
                        console.log('Received message from server:', data);
                        this.messages.push({
                            text: data,
                            identity: 1
                        });
                        this.scrollToBottom()
                        // 处理来自服务器的消息
                    });


                } else {
                    this.socket.emit('authenticate', { userId: this.socket.id, password: 'pass456' });
                    this.socket.on('authenticated', (data) => {
                        // console.log('Permanent ID:', data.permanentId);
                        this.userId = data.permanentId
                        // 存储permanentId，并在后续连接中发送
                        this.messages.push({
                            text: data.text,
                            identity: 1
                        });

                    });

                    userAdd({
                        userkey: this.socket.id,
                        state: 1
                    }).then((e) => {
                        // console.log(e);

                        sessionStorage.setItem("UserId", e.data.data1[0].id);
                        sessionStorage.setItem("Userkey", e.data.data1[0].userkey);

                        this.socket.on(sessionStorage.getItem('Userkey'), (data) => {
                            console.log('Received message from server:', data);
                            this.messages.push({
                                text: data,
                                identity: 1
                            });

                            this.scrollToBottom()
                        });

                    })
                }





                this.socket.on('authenticationFailed', () => {
                    console.error('Authentication failed');
                });


                // console.log('Socket.IO connection established successfully!');
                // if (sessionStorage.getItem('Userkey')) {
                //     // this.userId = sessionStorage.getItem('Userkey')
                //     // 监听来自服务器的消息
                //     this.socket.on(sessionStorage.getItem('Userkey'), (data) => {
                //         console.log('Received message from server:', data);
                //         this.messages.push({
                //             data,
                //             stId: 0
                //         });
                //         // 处理来自服务器的消息
                //     });
                // } else {


                //     // 在这里执行连接成功后的逻辑
                //     console.log(this.socket);
                //     this.userId = this.socket.id
                //     // 监听来自服务器的消息
                //     this.socket.on(this.socket.id, (data) => {
                //         console.log('Received message from server:', data);
                //         this.messages.push({
                //             data,
                //             stId: 0
                //         });
                //         // 处理来自服务器的消息
                //     });
                // }



            });

            // 可以添加其他事件监听器
            this.socket.on('disconnect', () => {
                console.log('Socket.IO connection disconnected!');
                // 在这里执行断开连接后的逻辑
            });


        },
    },

    beforeDestroy() {
        // this.socket.emit(this.userId, this.userId);
    }
}
</script>
<style>
.headers {

    /*标题设置*/

    height: 80px;
    /*高*/

    background-color: aquamarine;
    /*背景色*/

    line-height: 100px;
    /*行高,控制上下居中*/

    text-align: center;

    position: fixed;
    top: 70px;
    width: 100%;
    font-size: 25px;
    font-weight: 900;
    /*控制左右居中*/

}

.hgroups {

    /* 内容区样式*/
    /* width: 50%; */
    /* height: 300px; */

    background-color: azure;
    /* border: 1px solid red; */
    border-radius: 20px;
    min-height: 40%;
    max-height: 50%;
    overflow: auto;
    padding: 20px;
    /* overflow-y: scroll; */
    /*聊天内容过多时自动添加滚动条*/

}

.footers {

    /* 底部区样式*/

    display: flex;
    /* 控制底部框框和按钮在一排 横着显示的*/

}

.ipt {

    margin-right: 20px;
    /* 底部区框框和按钮中间的间隙*/

}

.user {
    /* 用户说话样式*/

    float: right;
    /* 用户说话自动靠右*/

    clear: both;
    /* 用户说话左边不能有东西*/

    display: flex;
    /* 头像和说话并排显示*/

    align-items: center;
    /* 头像在说话高度中间对齐*/
    margin-top: 10px;

}

.textstyle {
    /* 气泡框绿色背景样式*/

    padding: 10px;

    background-color: rgb(128, 210, 98);

    border-radius: 10px;

    max-width: 500px;

    word-wrap: normal;
    /* 说话太长自动换行*/

    line-height: 24px;

}

.system {
    /* 系统说话样式*/

    float: left;

    clear: both;

    display: flex;

    align-items: center;
    margin-top: 10px;

}
</style>
<!-- <script>
import io from 'socket.io-client';


export default {
    name: 'OnlineService',
    data() {
        return {
            messages: [],
            newMessage: '',
            socket: null,
        };
    },
    mounted() {
        // 连接Socket服务器
        this.socket = io('http://127.0.0.1:8000');

        // 监听服务端发来的消息事件，并将其添加到聊天记录中
        this.socket.on('message', (data) => {
            this.messages.push(data);
            this.$nextTick(() => {
                const el = this.$refs.messages;
                el.scrollTop = el.scrollHeight;
            });
        });
    },
    methods: {
        // 发送消息
        sendMessage() {
            if (this.newMessage.trim()) {
                this.socket.emit('message', this.newMessage);
                this.messages.push(`我：${this.newMessage}`);
                this.newMessage = '';
            }
        },
    },
};
</script> -->